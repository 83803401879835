import React from "react"

import styled from "@emotion/styled"

import PortfolioGallery, { SRLGalleryWrap } from "../Gallery/PortfolioGallery"

import { SRLWrapper, useLightbox } from "simple-react-lightbox"

const CTAHolder = styled.div`
  background-color: var(--brz-grey);
  width: 100vw;
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 24vh;
  display: flex;
  align-items: center;
`

const PortCTA = styled.div`
  display: block;
  margin-left: 6%;
  margin-right: 6%;
  width: 100%;

  && > h4 {
    display: block;
    color: white;
    margin-bottom: 2rem;
  }
  && > p {
    font-size: 18px;
    display: block;
  }
  @media all and (max-width: 550px) {
    && > h4 {
      font-size: 1.5rem;
      text-align: left;
    }
  }
`
const Portfolio = ({ title, description, pictures }) => {
  return (
    <>
      <CTAHolder>
        <PortCTA>
          <h4>{title}</h4>
          <div
            className={"white-cta"}
            style={{ color: "white" }}
            dangerouslySetInnerHTML={{ __html: `${description}` }}
          />
        </PortCTA>
      </CTAHolder>
      <PortfolioGallery imgArray={pictures} portfolioTitle={title} />
    </>
  )
}

Portfolio.propTypes = {}

export default Portfolio
