import React, { useState, useEffect } from "react"

import { Row, Container } from "react-bootstrap"
import { makeLink } from "../utils/google-pic-format"

import InnerWrap from "../components/_Wrap/inner-wrap"
import Hero, { FluidHero } from "../components/Hero"
import PortfolioGallery, {
  SRLGalleryWrap,
} from "../components/Gallery/PortfolioGallery"

import { SRLWrapper, useLightbox } from "simple-react-lightbox"

import Paragraph from "../components/Paragraph"
import SEO from "../components/seo"
import CTA from "../components/CTA"
import Portfolio from "../components/Portfolio"
const CommunityProjects = ({ data }) => {
  const { header, content } = data
  const [projects, setProjects] = useState([])

  useEffect(() => {
    let projectArray = []
    let num = -1
    //to keep track of index
    let d = 0
    content.edges.forEach((con, i) => {
      //first build an array of the titles

      let potentialProject = {}
      if (
        con.node.communityPageProjectTitle &&
        con.node.communityPageProjectTitle !== null &&
        con.node.communityPageProjectTitle.match(/^(?!\s*$).+/)
      ) {
        potentialProject["name"] = con.node.communityPageProjectTitle
        potentialProject["description"] =
          con.node.communityPageProjectDescription
        potentialProject["pictures"] = []
        projectArray.push(potentialProject)
        num++
      } else {
        const obj = projectArray[num]
        obj.pictures = [
          ...obj.pictures,
          {
            index: d,
            name: con.node.projectPictureTitle,
            link: makeLink(con.node.projectPictureLink),
            description: con.node.projectPictureDescription,
          },
        ]
        ++d
      }
    })

    setProjects(projectArray)
  }, [content.edges])

  return (
    <React.Fragment>
      <SEO
        title="Community Projects"
        keywords={[`brazee`, `cincinnati`, `art classes`, `corporate awards`]}
      />
      {/* <Hero src={makeLink(header.edges[0].node.headerPicture)} /> */}
      <FluidHero
        name={`${encodeURIComponent(
          header.edges[0].node.headerPictureTitle
        )}.png`}
      ></FluidHero>
      <Container fluid>
        <Row>
          <InnerWrap>
            <Paragraph
              title={header.edges[0].node.pagetitle}
              subheading={header.edges[0].node.communityPageSubtitle}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: header.edges[0].node.communityPageParagraph.replace(
                    /\n/g,
                    "<br /> "
                  ),
                }}
              ></p>
            </Paragraph>
          </InnerWrap>
        </Row>
        <Row>
          <SRLWrapper style={{ margin: "auto !important" }}>
            {projects.map(project => (
              <Portfolio
                key={`portfolio-gallery--${project.name}`}
                title={project.name}
                description={project.description}
                pictures={project.pictures}
              />
            ))}
          </SRLWrapper>
        </Row>
        <Row>
          <CTA />
        </Row>
      </Container>
      <div style={{ height: "50px", backgroundColor: "white" }}></div>
    </React.Fragment>
  )
}

export const communityQuery = graphql`
  query CommunityProjectMultiQuery {
    header: allGoogleSheetCommunityProjectsRow {
      edges {
        node {
          headerPicture
          headerPictureTitle
          pagetitle
          communityPageSubtitle
          communityPageParagraph
        }
      }
    }
    content: allGoogleSheetCommunityProjectsRow {
      edges {
        node {
          communityPageProjectTitle
          communityPageProjectDescription
          projectPictureTitle
          projectPictureLink
          projectPictureDescription
        }
      }
    }
  }
`

export default CommunityProjects
